import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'OrgUserInfo',
	data(){
		return{
			userInfo:''
		}
	},
	methods:{
		GetUserInfo(){ //获取用户信息
			var that = this
			CheckInService.GetUserOrgInfo(that.$UserID).then(res=>{
				if(res.Tag == 1){
					that.userInfo = res.Result
				}
			})
		},
		toEditUser(){ //编辑用户
			this.$router.push({
				path:'/EditOrgUser'
			})
		},
		toPurchase(){ //会员续费
			this.$alert('网站建设中', '提示', {
				confirmButtonText: '确定',
			});
		}
	},
	created(){
		this.GetUserInfo()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'UserLeft':UserLeft
	}
}